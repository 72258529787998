import {
  Badge,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  Flex,
  Heading,
  Link,
  Progress,
  SegmentedControl,
  Separator,
  Spinner,
  Strong,
  Text,
  TextField,
  Tooltip,
} from "@radix-ui/themes";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { Gi3DStairs, GiCharacter, GiKiwiFruit } from "react-icons/gi";
import { useDebounce } from "@uidotdev/usehooks";
import FandomIcon from "../../../../public/icons/fandom.svg";
import styles from "./heroGradient.module.css";
import backgroundStyles from "./backgroundGradient.module.css";
import heroVideoZoomStyles from "./heroVideoZoom.module.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { trpc } from "@/utils/trpc";

import {
  GetServerSideProps,
  InferGetServerSidePropsType,
  NextApiResponse,
} from "next";
import { userService } from "@/backend/services/users";
import { appRouter } from "@/app/api/trpc/[trpc]/route.api";
import { ScenarioHomeRow } from "@/pages/app/home/scenarioHomeRow";
import { BannerHomeRow } from "@/pages/app/home/bannerHomeRow";
import { CharacterHomeRow } from "@/pages/app/home/characterHomeRow";
import useBodyPlayVideo from "@/pages/app/home/heroVideo";
import { toast } from "sonner";
import { useUser } from "@/hooks/useUser";
import {
  SearchScenarioTime,
  SearchScenarioType,
} from "@/backend/services/scenarios/searchScenarioTime";
import { FlexibleScenarioRow } from "@/pages/app/characters/[characterId]/flexibleScenarioRow";
import { SpellboundIcon } from "@/pages/app/home/spellboundIcon";
import { GlobalHeader } from "@/pages/app/home/globalHeader";
import { FrontendScenario } from "@/backend/frontendScenario";
import { FrontendCharacter } from "@/pages/app/home/frontendCharacter";
import { useRouterUtils } from "@/app/routerUtils";
import { CharacterSortType } from "@/backend/services/characters/characterSortType";
import { useUserProfile } from "@/utils/auth/useUserProfile";
import { usePostHog } from "posthog-js/react";
import { SearchCharacterTime } from "@/backend/services/characters/searchCharacter";
import { NextSeo } from "next-seo";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { RenderableUserProfile } from "@/pages/api/trpc/user/renderableUserProfile";
import { Fingerprint } from "./fingerprint";
import { PiBroom, PiFlowerFill, PiPencil } from "react-icons/pi";
import { AuthDialogSignedInContent } from "../auth/authDialogSignedInContent";
import { AuthDialogSignedOutContent } from "../auth/authDialogSignedOutContent";
import { useAnalytics } from "@/analyticsContext";
import { generateReferralCode } from "@/shared/referral";
import { ReferralMessage } from "./referralMessage";
import { SearchModule } from "./searchModule";

const newCharacterCount = 10;
export const getServerSideProps = (async (context) => {
  const user = await userService.getUserFromRequest(
    context.req,
    context.res as NextApiResponse,
  );
  console.log("user", user);
  const caller = appRouter.createCaller({
    user: user,
    isAnonymous: !user?.supabaseUid,
    ip: context.req.socket.remoteAddress || "",
  });
  const newCharactersPromise = caller.character.getNewCharacters({
    allowAdult: user?.allowAdult ?? false,
    limit: newCharacterCount,
  });
  const createdCharactersPromise =
    user && user.supabaseUid
      ? caller.character.getCreatedCharacters({
          limit: 100,
        })
      : Promise.resolve(undefined);

  const topStoryRowPromise = caller.scenarios.getRandomScenarios({
    limit: 6,
    searchPeriod: SearchScenarioTime.Day,
    searchType: SearchScenarioType.WordCount,
  });
  const adventureRowPromise = caller.scenarios.getRandomScenarios({
    limit: 5,
    searchIds: [20288, 19132, 14506, 10180, 14612, 8524],
  });
  const confrontationRowPromise = caller.scenarios.getRandomScenarios({
    limit: 5,
    searchIds: [11291, 25085, 14096, 6550, 10144],
  });
  const companionshipRowPromise = caller.scenarios.getRandomScenarios({
    limit: 5,
    searchIds: [31530, 25537, 10150, 16112, 9527],
  });
  const recommendedCharactersPromise =
    caller.character.getRecommendedCharacters({
      pooling: "max",
    });
  const discoverCharactersPromise = caller.character.getRecommendedCharacters({
    pooling: "max",
    searchTime: SearchCharacterTime.Week,
    limit: 10,
  });
  const mostRecentCharactersPromise =
    caller.users.getUserMostSpokenToCharacters({
      limit: 8,
    });
  const userProfilePromise = caller.users.getCurrentUserProfile({});

  const startTime = Date.now();
  if (false) {
    const [
      newCharacters,
      topStoryRow,
      confrontationRow,
      companionshipRow,
      adventureRow,
      createdCharactersRow,
      recommendedCharacters,
      discoverCharacters,
      mostRecentCharacters,
      userProfile,
    ] = await Promise.all([
      newCharactersPromise.then((result) => {
        console.log(`newCharactersPromise took ${Date.now() - startTime}ms`);
        return result;
      }),
      topStoryRowPromise.then((result) => {
        console.log(`topStoryRowPromise took ${Date.now() - startTime}ms`);
        return result;
      }),
      confrontationRowPromise.then((result) => {
        console.log(`confrontationRowPromise took ${Date.now() - startTime}ms`);
        return result;
      }),
      companionshipRowPromise.then((result) => {
        console.log(`companionshipRowPromise took ${Date.now() - startTime}ms`);
        return result;
      }),
      adventureRowPromise.then((result) => {
        console.log(`adventureRowPromise took ${Date.now() - startTime}ms`);
        return result;
      }),
      createdCharactersPromise.then((result) => {
        console.log(
          `createdCharactersPromise took ${Date.now() - startTime}ms`,
        );
        return result;
      }),
      recommendedCharactersPromise.then((result) => {
        console.log(
          `recommendedCharactersPromise took ${Date.now() - startTime}ms`,
        );
        return result;
      }),
      discoverCharactersPromise.then((result) => {
        console.log(
          `discoverCharactersPromise took ${Date.now() - startTime}ms`,
        );
        return result;
      }),
      mostRecentCharactersPromise.then((result) => {
        console.log(
          `mostRecentCharactersPromise took ${Date.now() - startTime}ms`,
        );
        return result;
      }),
      userProfilePromise.then((result) => {
        console.log(`userProfilePromise took ${Date.now() - startTime}ms`);
        return result;
      }),
    ]);
    console.log(`Total time for all promises: ${Date.now() - startTime}ms`);
    return {
      props: {
        newCharacters: newCharacters.items,
        topStoryRow,
        companionshipRow,
        confrontationRow,
        adventureRow,
        createdCharactersRow: createdCharactersRow?.items ?? undefined,
        recommendedCharacters: recommendedCharacters.characters,
        discoverCharacters: discoverCharacters.characters,
        mostRecentCharacters: mostRecentCharacters.characters,
        userProfile: userProfile.user,
      },
    };
  } else {
    const [createdCharactersRow, mostRecentCharacters, userProfile] =
      await Promise.all([
        createdCharactersPromise.then((result) => {
          console.log(
            `createdCharactersPromise took ${Date.now() - startTime}ms`,
          );
          return result;
        }),
        mostRecentCharactersPromise.then((result) => {
          console.log(
            `mostRecentCharactersPromise took ${Date.now() - startTime}ms`,
          );
          return result;
        }),
        userProfilePromise.then((result) => {
          console.log(`userProfilePromise took ${Date.now() - startTime}ms`);
          return result;
        }),
      ]);

    return {
      props: {
        newCharacters: [],
        topStoryRow: [],
        companionshipRow: [],
        confrontationRow: [],
        adventureRow: [],
        createdCharactersRow: createdCharactersRow?.items ?? undefined,
        recommendedCharacters: [],
        discoverCharacters: [],
        mostRecentCharacters: mostRecentCharacters.characters,
        userProfile: userProfile.user,
      },
    };
  }
}) satisfies GetServerSideProps<{
  newCharacters: FrontendCharacter[];
  topStoryRow: FrontendScenario[];
  companionshipRow: FrontendScenario[];
  confrontationRow: FrontendScenario[];
  adventureRow: FrontendScenario[];
  createdCharactersRow?: FrontendCharacter[];
  recommendedCharacters: FrontendCharacter[];
  discoverCharacters: FrontendCharacter[];
  mostRecentCharacters: FrontendCharacter[];
  userProfile: RenderableUserProfile | undefined;
}>;

export default function Index({
  newCharacters,
  adventureRow,
  companionshipRow,
  confrontationRow,
  topStoryRow,
  createdCharactersRow,
  recommendedCharacters,
  discoverCharacters,
  mostRecentCharacters,
  userProfile,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [searchSortType, setSearchSortType] = useState<CharacterSortType>(
    CharacterSortType.Trending,
  );
  useEffect(() => {
    const previousSearchType = window.localStorage.getItem("defaultSearchType");
    if (previousSearchType) {
      setSearchSortType(previousSearchType as CharacterSortType);
    }
  }, []);
  useEffect(() => {
    window.localStorage.setItem("defaultSearchType", searchSortType);
  }, [searchSortType]);
  const debouncedSearchTerm = useDebounce(searchTerm.trim(), 300);
  const referralCount = userProfile?.referralCount;
  const characters = newCharacters;
  //const videoRef = useBodyPlayVideo();
  const [scenarioSearch] = trpc.useQueries((t) => [
    t.scenarios.getRandomScenarios(
      {
        searchPeriod: SearchScenarioTime.AllTime,
        limit: 10,
        searchTerm: debouncedSearchTerm,
        searchType: SearchScenarioType.SemanticSimilarity,
      },
      {
        enabled: debouncedSearchTerm?.length > 0,
      },
    ),
  ]);
  const [tags, setTags] = useState<Set<string>>(new Set());
  const isSearching = true;
  const characterSearch = trpc.character.getCharacters.useInfiniteQuery(
    {
      limit: userProfile ? 30 : 15,
      searchTerm: debouncedSearchTerm,
      allowAdult: userProfile?.allowAdult ?? false,
      categories: [],
      duration: "all-time",
      genders: [],
      tags: Array.from(tags),
      sortType:
        debouncedSearchTerm.length > 0
          ? CharacterSortType.Semantic
          : searchSortType,
    },
    {
      enabled: isSearching,
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      trpc: { abortOnUnmount: true },
    },
  );

  const [sentryRef] = useInfiniteScroll({
    loading: characterSearch.isLoading,
    hasNextPage: characterSearch.hasNextPage,
    onLoadMore: () => characterSearch.fetchNextPage(),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!characterSearch.error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  const analytics = useAnalytics();
  const showUserCharacters =
    debouncedSearchTerm.length == 0 && tags.size == 0 && userProfile;
  return (
    <>
      <NextSeo
        title={`Spellbound - interactive storytelling`}
        description={`Tell your own story with Spellbound. Choose your own adventure stories, featuring you as the main character. Choose from over 7,000 characters and stories.`}
      />
      <Fingerprint userProfile={userProfile} />
      <Box
        className={`${backgroundStyles.backgroundGradient} w-full max-w-full min-h-screen`}
        px={{ initial: "1", md: "4" }}
      >
        <AddFromCaiDialog disableTrigger={true} />
        <Flex
          width={"100%"}
          pt={"3"}
          mb={"4"}
          direction={"column"}
          gap={{ initial: "5", lg: "7" }}
        >
          <GlobalHeader
            onClickSpellbound={() => setSearchTerm("")}
            enableSearch={false}
            additionalContent={
              userProfile && (
                <AddCharacterDialog
                  trigger={
                    <Button
                      size="2"
                      color={"jade"}
                      className={"dark backdrop-blur-lg"}
                      style={{
                        transform: "translateZ(0)",
                      }}
                      onClick={() =>
                        analytics.capture("homePage.ctaClicked", {
                          ctaType: "importCharacter",
                        })
                      }
                    >
                      Create
                    </Button>
                  }
                />
              )
            }
          />

          <SearchModule
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setTags={setTags}
            tags={tags}
            setSearchSortType={setSearchSortType}
            searchSortType={searchSortType}
          />
          {showUserCharacters && (
            <Text align={"center"}>
              <b>
                Tip: Scroll to the bottom of the page to see characters you've
                created!{" "}
              </b>
            </Text>
          )}
          {!debouncedSearchTerm.length && (
            <Flex
              direction={"column"}
              style={{
                transition: "all",
                height: isSearching ? 0 : undefined,
                overflowY: "hidden",
              }}
            >
              {!userProfile && (
                <div
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    height: "30rem",
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: "var(--radius-3)",
                    boxShadow: "var(--shadow-1)",
                  }}
                >
                  <video
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      transform: "translate(0)",
                      borderRadius: "var(--radius-3)",
                      boxShadow: "var(--shadow-1)",
                    }}
                    className={`${heroVideoZoomStyles.videoZoomOut}`}
                    preload={"true"}
                    ref={(video) => {
                      if (video) {
                        video.playbackRate = 2;
                      }
                    }}
                    autoPlay={true}
                    muted={true}
                    playsInline={true}
                    loop={true}
                    color={"#FFF"}
                  >
                    <source
                      src="https://ik.imagekit.io/x2dirkim6/images/site/hero-hd.mp4?updatedAt=1721553836686"
                      type="video/mp4"
                    />
                  </video>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Flex
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 1,
                      }}
                      direction={"column"}
                      align={"center"}
                      className={"flex justify-center items-top"}
                      p={"3"}
                      px={"8"}
                    >
                      <Heading
                        align={"center"}
                        style={{
                          cursor: "default",
                        }}
                        size={{ initial: "7", md: "9" }}
                        className={`dark ${styles.textGradient}  font-bold lg:text-7xl md:text-6xl sm:text-4xl text-4xl`}
                      >
                        Become one with the MC.
                      </Heading>
                      <Text
                        size={"8"}
                        mt={"3"}
                        className={"lg:text-2xl md:text-xl text-xl  shadow-2xl"}
                        align={"center"}
                        style={{
                          cursor: "default",
                          color: "var(--white-a11)",
                        }}
                      >
                        Read a story where{" "}
                        <Strong>you control the main character.</Strong>
                      </Text>
                      <Flex gap={"4"} justify={"center"} wrap={"wrap"}>
                        <OnboardingDialog />
                        <Button
                          asChild
                          size={{ initial: "3", sm: "4" }}
                          variant={"soft"}
                          radius={"full"}
                          color={"indigo"}
                          className={"mt-4 dark backdrop-blur-lg"}
                          style={{
                            transform: "translateZ(0)",
                          }}
                          onClick={() =>
                            analytics.capture("homePage.ctaClicked", {
                              ctaType: "discord",
                            })
                          }
                        >
                          <a
                            href="https://discord.gg/spellbound"
                            target={"_blank"}
                          >
                            Join Our Discord
                          </a>
                        </Button>
                        <AddCharacterDialog
                          trigger={
                            <Button
                              size={{ initial: "3", sm: "4" }}
                              variant={"soft"}
                              radius={"full"}
                              color={"jade"}
                              className={"mt-4 dark backdrop-blur-lg"}
                              style={{
                                transform: "translateZ(0)",
                              }}
                              onClick={() =>
                                analytics.capture("homePage.ctaClicked", {
                                  ctaType: "importCharacter",
                                })
                              }
                            >
                              Import a character
                            </Button>
                          }
                        />
                      </Flex>
                    </Flex>
                    <div
                      className={"opacity-70"}
                      style={{
                        position: "absolute",
                        top: "-2px",
                        left: "-2px",
                        width: "103%",
                        height: "103%",
                        backgroundColor: "black",
                      }}
                    />
                  </div>
                </div>
              )}

              <Box my="7" />
              <ScenarioHomeRow
                scenarios={topStoryRow}
                title={"Most popular"}
                loadEarly={true}
                subtitle={"What have we been reading this week?"}
              />

              {
                <>
                  <Box my="7" />
                  <CharacterHomeRow
                    characters={characters}
                    title={"Meet our newest adventurers"}
                    subtitle={"(We've got 7,000 of them and counting.)"}
                  />
                </>
              }
              {mostRecentCharacters && (
                <>
                  <Box my="7" />
                  <CharacterHomeRow
                    characters={mostRecentCharacters}
                    title={"Your most spoken to characters"}
                  />
                </>
              )}

              <Box my="5" />
              {recommendedCharacters && (
                <CharacterHomeRow
                  characters={recommendedCharacters}
                  title={"Try these related characters"}
                />
              )}
              <Box my="5" />

              <Box my="7" />
              {discoverCharacters && (
                <CharacterHomeRow
                  characters={discoverCharacters}
                  title={"These are fresh characters we think you'll like"}
                />
              )}

              <Box my="7" />
              <ScenarioHomeRow
                scenarios={confrontationRow}
                title={"Confrontations"}
                subtitle={
                  "Tired of being the hero? Try working with the other side for once"
                }
              />

              <Box my="4" />
              <ScenarioHomeRow
                scenarios={adventureRow}
                title={"Adventure awaits"}
                subtitle={"Halloween is coming up, get in the spirit!"}
              />
              <Box my="4" />
              <ScenarioHomeRow
                scenarios={companionshipRow}
                title={"Need a listening ear?"}
                subtitle={"The stories we've spent the most time on together."}
              />
              <Box my="7" />
              {userProfile && createdCharactersRow && (
                <CharacterHomeRow
                  characters={createdCharactersRow}
                  title={"Your created characters"}
                />
              )}
              <Box my="3" />

              <Flex
                width={"100%"}
                justify={"center"}
                align={"center"}
                direction={"column"}
              >
                <SpellboundIcon color={"var(--gray-12)"} />
                {userProfile && (
                  <AddCharacterDialog
                    trigger={
                      <Link color={"lime"} className={"cursor-pointer"}>
                        <Strong>import</Strong>
                      </Link>
                    }
                  />
                )}
                {userProfile && (
                  <Link
                    color={"lime"}
                    className={"cursor-pointer"}
                    href="/app/characters/create"
                  >
                    <Strong>create adventurer</Strong>
                  </Link>
                )}
                <Link
                  href={
                    "https://pentagonal-icebreaker-2a3.notion.site/Spellbound-Careers-4fc0e4fa48f54c1d95eb5d7c6cef68dc"
                  }
                  color={"lime"}
                >
                  <Strong>careers</Strong>
                </Link>
                <Link href={"https://discord.gg/spellbound"} color={"lime"}>
                  <Strong>discord</Strong>
                </Link>
                <Link href={"https://twitter.com/spellboundai"} color={"lime"}>
                  <Strong>twitter</Strong>
                </Link>
                <Link
                  href={
                    "https://tryspellbound.notion.site/Spellbound-Terms-Of-Service-93ddef119a214547acaacaef2d8323e0?pvs=4"
                  }
                  target="_blank"
                >
                  <Strong>terms of service | privacy</Strong>
                </Link>
              </Flex>
            </Flex>
          )}
          <Flex direction={"column"}>
            {isSearching && (
              <>
                <Flex gap={"2"} align={"center"} my={"4"}>
                  {(() => {
                    switch (true) {
                      case characterSearch?.data?.pages.flatMap(
                        (it) => it.items,
                      ).length === 0:
                        return (
                          <Flex gap={"2"}>
                            <Heading>No results found</Heading>
                            <Button
                              variant={"outline"}
                              onClick={() => {
                                setTags(new Set());
                              }}
                            >
                              Clear Tags
                            </Button>
                          </Flex>
                        );
                      case debouncedSearchTerm.length > 0:
                        return (
                          <Heading>
                            Your results for '{debouncedSearchTerm}'{" "}
                          </Heading>
                        );
                      case tags.size > 0:
                        return (
                          <Heading>
                            Your results for '{Array.from(tags).join(", ")}'{" "}
                          </Heading>
                        );
                      default:
                        return (
                          <Heading>
                            Characters sorted by {searchSortType}{" "}
                          </Heading>
                        );
                    }
                  })()}
                  <Spinner loading={characterSearch.isLoading} />
                </Flex>
                <CharacterHomeRow
                  characters={characterSearch?.data?.pages.flatMap(
                    (it) => it.items,
                  )}
                  placeholderCount={16}
                />
                {characterSearch.hasNextPage && (
                  <Flex
                    justify={"center"}
                    mt={"4"}
                    ref={showUserCharacters ? undefined : sentryRef}
                  >
                    <Button
                      size="4"
                      variant="outline"
                      onClick={() => characterSearch.fetchNextPage()}
                      loading={characterSearch.isFetchingNextPage}
                    >
                      Load more
                      <FaMagnifyingGlass />
                    </Button>
                  </Flex>
                )}
                {showUserCharacters && (
                  <>
                    <Separator size={"4"} my="9" />
                    {mostRecentCharacters && (
                      <>
                        <CharacterHomeRow
                          characters={mostRecentCharacters}
                          title={"Your most recent characters"}
                        />
                      </>
                    )}
                    <Box my="9" />
                    {userProfile && createdCharactersRow && (
                      <CharacterHomeRow
                        characters={createdCharactersRow}
                        title={"Your created characters"}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  );
}

function OnboardingGenreCard({
  title,
  image,
  imageAlt,
  onClick,
  isLoading,
  isFaded,
}: {
  title?: string;
  image?: string;
  imageAlt?: string;
  isLoading: boolean;
  onClick?: () => void;
  isFaded: boolean;
}) {
  return (
    <Card
      asChild
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        transition: "all 0.5s linear",
        opacity: isFaded ? 0.3 : 1,
        maxHeight: isFaded ? "0rem" : "10rem",
      }}
      size={"5"}
    >
      <Button
        color={"gray"}
        loading={isLoading}
        onClick={() => onClick()}
        style={{
          pointerEvents: isLoading || isFaded ? "none" : "auto",
        }}
      >
        <Flex
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            color: "white",
            textShadow:
              "0px 0px 2px rgba(0,0,0,1), 0px 0px 5px rgba(0,0,0,1), 0px 0px 10px rgba(0,0,0,1)",
          }}
          align={"center"}
          justify={"center"}
        >
          <Heading size={"8"} className={" z-50  align-middle"}>
            {title}
          </Heading>
        </Flex>
        <Flex
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0.5,
          }}
          className={heroVideoZoomStyles.videoZoomOut}
        >
          <img
            src={image}
            alt={imageAlt}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "top",
            }}
          />
        </Flex>
      </Button>
    </Card>
  );
}

function OnboardingDialog() {
  const { openStory } = useRouterUtils();
  const createConversation =
    trpc.conversations.createConversationWithScenario.useMutation({
      onSuccess: (data) => openStory(data.id),
    });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState<string | null>(null);
  const callback = useCallback(
    async (genre: string) => {
      setSelectedGenre(genre);
      setIsLoading(true);
      let scenarioId: number;
      switch (genre) {
        case "anime":
          scenarioId = 17301;
          break;
        case "comics":
          scenarioId = 25383;
          break;
        case "movies":
          scenarioId = 12426;
          break;
        default:
          scenarioId = 12426;
      }
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          createConversation
            .mutateAsync({
              scenarioId: scenarioId,
            })
            .then(() => resolve());
        }, 2000);
      });
    },
    [createConversation],
  );
  const posthog = usePostHog();
  const analytics = useAnalytics();
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Button
          size={{ initial: "3", sm: "4" }}
          variant={"solid"}
          radius={"full"}
          color={"lime"}
          className={`mt-4 dark backdrop-blur-lg transition-opacity`}
          style={{
            transform: "translateZ(0)",
            //Must match backgroundGradient.module.css value
            transition:
              "background-color 0.5s linear, border-color 0.5s linear, color 0.5s linear",
          }}
          onClick={() => {
            posthog.capture("onboarding_started");
            analytics.capture("homePage.ctaClicked", {
              ctaType: "onboardingStarted",
            });
          }}
        >
          Write a story
        </Button>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="450px">
        <Dialog.Title>
          {isLoading ? (
            "We're serving up your story…"
          ) : (
            <>Welcome to Spellbound!</>
          )}
        </Dialog.Title>
        <Dialog.Description size="3" mb="4">
          {isLoading
            ? "It'll be just a moment"
            : "What genre are you most into?"}
        </Dialog.Description>

        <Flex direction="column" gap="3" wrap={{ initial: "wrap" }}>
          <OnboardingGenreCard
            onClick={async () => {
              posthog.capture("onboarding_genre_selected", { genre: "anime" });
              await callback("anime");
            }}
            isLoading={isLoading && selectedGenre === "anime"}
            isFaded={!!selectedGenre && selectedGenre !== "anime"}
            title={"Anime"}
            image={"/images/onboarding/anime.webp"}
            imageAlt={
              "An image representing anime and mecha culture, featuring a vibrant cityscape with neon lights, animated characters in anime style, and a towering mecha robot integrated into the city environment."
            }
          />
          <OnboardingGenreCard
            onClick={async () => {
              posthog.capture("onboarding_genre_selected", { genre: "comics" });
              await callback("comics");
            }}
            isFaded={!!selectedGenre && selectedGenre !== "comics"}
            isLoading={isLoading && selectedGenre === "comics"}
            title={"Comics"}
            image={"/images/onboarding/comics.webp"}
            imageAlt={
              "A close-up view of a comic book page, heavily stylized with a pronounced dot print (halftone effect) ."
            }
          />{" "}
          <OnboardingGenreCard
            onClick={async () => {
              posthog.capture("onboarding_genre_selected", { genre: "movies" });
              await callback("movies");
            }}
            isFaded={!!selectedGenre && selectedGenre !== "movies"}
            isLoading={isLoading && selectedGenre === "movies"}
            title={"Movies"}
            image={"/images/onboarding/movies.webp"}
            imageAlt={
              "An artistic collage representing the essence of movies, paying homage to various famous films without using copyrighted characters. The image includes"
            }
          />
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function SecondaryAddCharacterDialog({
  trigger,
}: {
  trigger: React.ReactNode;
}) {
  return (
    <Dialog.Root>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>

      <Dialog.Content maxWidth="450px">
        <Dialog.Title>
          <Heading
            style={{
              fontFamily: "var(--font-figtree)",
            }}
          >
            Create a Character
          </Heading>
        </Dialog.Title>
        <Dialog.Description size="2" mb="4">
          <Text>Choose one of the following options.</Text> <br />
        </Dialog.Description>

        <Flex direction="column" gap="3">
          <AddFromFandomDialog />
          <AddFromYodayoDialog />
          <AddFromSakuraDialog />
          <AddFromFiggsDialog />
          <AddFromCaiDialog />
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray" onClick={() => {}}>
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button>Save</Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function AddCharacterDialog({ trigger }: { trigger: React.ReactNode }) {
  const { userProfile } = useUserProfile();
  return (
    <Dialog.Root>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>

      {userProfile ? (
        <Dialog.Content maxWidth="450px">
          <Dialog.Title>
            <Heading
              style={{
                fontFamily: "var(--font-figtree)",
              }}
            >
              Create a Character
            </Heading>
          </Dialog.Title>
          <Dialog.Description size="2" mb="4">
            <Text>Choose one of the following options.</Text> <br />
          </Dialog.Description>

          <Flex direction="column" gap="3">
            <Card
              asChild
              className="cursor-pointer"
              size={"4"}
              variant="classic"
            >
              <Link
                color="tomato"
                className={"cursor-pointer w-full"}
                href="/app/characters/create"
                size={"6"}
              >
                <Flex align="center" justify="center" className="w-full h-full">
                  <Text align="center">
                    <Strong>
                      <span className="inline-flex items-center gap-1">
                        Create From Scratch <PiPencil />
                      </span>
                    </Strong>
                  </Text>
                </Flex>
              </Link>
            </Card>
            <Flex align={"center"} justify={"center"} gap={"2"}>
              <Separator size="4" />
              <Text>Or</Text>
              <Separator size="4" />
            </Flex>
            <AddFromFandomDialog />
            <AddFromYodayoDialog />
            <AddFromSakuraDialog />
            <AddFromFiggsDialog />
            <AddFromCaiDialog />
            <AddFromJanitorDialog />
          </Flex>

          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray" onClick={() => {}}>
                Cancel
              </Button>
            </Dialog.Close>
            <Dialog.Close>
              <Button>Save</Button>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      ) : (
        <AuthDialogSignedOutContent title="Sign in to import characters" />
      )}
    </Dialog.Root>
  );
}

function AddFromFandomDialog() {
  const { openCharacter } = useRouterUtils();
  const importCharacter = trpc.generate.createCharacterFromFandom.useMutation({
    onSuccess: (character) => {
      openCharacter(character.id);
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        `Failed to import character due to an internal error ${error}`,
        { duration: 20000 },
      );
    },
  });
  const [url, setUrl] = useState("");
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Card asChild>
          <Button
            color={"tomato"}
            className={"transition duration-300 ease-in-out transform h-16"}
          >
            <Flex align={"center"} justify={"center"} gap={"1"}>
              <Box width={"32"} height={"32px"}>
                <FandomIcon />
              </Box>
              <Text>Fandom.com import</Text>
            </Flex>
          </Button>
        </Card>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Beta: Import a character from Fandom.com</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Enter the URL below
          <br />
          <br />
          <Text>
            Importing characters bumps existing characters off the new character
            list, be thoughtful of your fellow users
          </Text>
          <br />
          <br />
          <Text>
            <Strong>
              Please avoid importing underage characters. <br /> <br /> Please
              be patient: this process takes about 30 seconds.
              <br />
              <br />
              <Text color="tomato">
                <Strong>
                  You cannot edit characters imported from Fandom.com.
                </Strong>
              </Text>
            </Strong>
          </Text>
        </Dialog.Description>

        <Flex direction="column" gap="3">
          <TextField.Root
            onChange={(e) => setUrl(e.currentTarget.value)}
            placeholder="Enter a valid Fandom.com URL…"
          />
          <Button
            onClick={() => {
              importCharacter.mutate({
                fandomUrl: url,
              });
            }}
            loading={importCharacter.isPending}
          >
            Submit
          </Button>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray" onClick={() => {}}>
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button loading={importCharacter.isPending}>Save</Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function AddFromSakuraDialog() {
  const { openCharacter } = useRouterUtils();
  const importCharacter = trpc.generate.createCharacterFromSakura.useMutation({
    onSuccess: (character) => {
      openCharacter(character.id);
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        `Failed to import character due to an internal error ${error}`,
        { duration: 20000 },
      );
    },
  });
  const [url, setUrl] = useState("");
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Card asChild>
          <Button
            color={"tomato"}
            className={"transition duration-300 ease-in-out transform h-16"}
          >
            <Flex align={"center"} justify={"center"} gap={"1"}>
              <Box width={"32"} height={"32px"}>
                <PiFlowerFill size={32} />
              </Box>
              <Text>Sakura.fm import</Text>
            </Flex>
          </Button>
        </Card>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Beta: Import a character from Sakura.fm</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Enter the URL below
          <br />
          <br />
          <Text>
            Use the "Copy Link" button on a Sakura.fm character page to get the
            URL
          </Text>
          <br />
          <br />
          <Text>
            <Strong>
              Please avoid importing underage characters. <br /> <br /> Please
              be patient: this process takes about 30 seconds.
            </Strong>
          </Text>
        </Dialog.Description>

        <Flex direction="column" gap="3">
          <TextField.Root
            onChange={(e) => setUrl(e.currentTarget.value)}
            placeholder="Enter a valid Sakura.fm URL…"
          />
          <Button
            onClick={() => {
              importCharacter.mutate({
                sakuraUrl: url,
              });
            }}
            loading={importCharacter.isPending}
          >
            Submit
          </Button>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray" onClick={() => {}}>
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button loading={importCharacter.isPending}>Save</Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function AddFromJanitorDialog() {
  const { openCharacter } = useRouterUtils();
  const importCharacter = trpc.generate.createCharacterFromJanitor.useMutation({
    onSuccess: (character) => {
      openCharacter(character.id);
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        `Failed to import character due to an internal error ${error}`,
        { duration: 20000 },
      );
    },
  });
  const [url, setUrl] = useState("");
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Card asChild>
          <Button
            color={"tomato"}
            className={"transition duration-300 ease-in-out transform h-16"}
          >
            <Flex align={"center"} justify={"center"} gap={"1"}>
              <Box width={"32"} height={"32px"}>
                <PiBroom size={32} />
              </Box>
              <Text>Janitor.ai import</Text>
            </Flex>
          </Button>
        </Card>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Beta: Import a character from Janitor.ai</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Enter the URL below
          <br />
          <br />
          <Text>
            Use the "Copy Link" button on a Janitor.ai character page to get the
            URL
          </Text>
          <br />
          <br />
          <Text>
            <Strong>
              Please avoid importing underage characters. <br /> <br /> Please
              be patient: this process takes about 30 seconds.
            </Strong>
          </Text>
        </Dialog.Description>

        <Flex direction="column" gap="3">
          <TextField.Root
            onChange={(e) => setUrl(e.currentTarget.value)}
            placeholder="Enter a valid Janitor.ai URL…"
          />
          <Button
            onClick={() => {
              importCharacter.mutate({
                janitorUrl: url,
              });
            }}
            loading={importCharacter.isPending}
          >
            Submit
          </Button>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray" onClick={() => {}}>
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button loading={importCharacter.isPending}>Save</Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
function AddFromCaiDialog({
  disableTrigger = false,
}: {
  disableTrigger: boolean;
}) {
  const { openCharacter } = useRouterUtils();
  const { userProfile } = useUserProfile();
  const importCharacter = trpc.generate.createCharacterFromCai.useMutation({
    onSuccess: (character) => {
      openCharacter(character.id);
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        `Failed to import character due to an internal error ${error}`,
        { duration: 20000 },
      );
    },
  });
  const [url, setUrl] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const { hash } = window.location;

    if (hash === "#cai") {
      setOpen(true);
      console.log("hash", hash);
      // Remove the hash from the URL without triggering a page reload
      history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search,
      );
      const urlParams = new URLSearchParams(window.location.search);
      const caiLink = decodeURIComponent(urlParams.get("caiLink") || "");
      console.log("caiLink", caiLink);
      if (caiLink) {
        setUrl(caiLink);
        setOpen(true);
        importCharacter.mutateAsync({
          caiUrl: caiLink,
        });
      }
    }
  }, []);
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      {!disableTrigger && (
        <Dialog.Trigger>
          <Card asChild>
            <Button
              color={"tomato"}
              className={"transition duration-300 ease-in-out transform h-16"}
            >
              <Flex align={"center"} justify={"center"} gap={"1"}>
                <Box width={"32"} height={"32px"}>
                  <GiCharacter size={32} />
                </Box>
                <Text>Character.AI import</Text>
              </Flex>
            </Button>
          </Card>
        </Dialog.Trigger>
      )}

      <Dialog.Content maxWidth="800px">
        <Dialog.Title>Beta: Import a character from Character.AI</Dialog.Title>
        {importCharacter.isPending ? (
          <Dialog.Description size="5" mb="4">
            <Text>
              <Strong>
                We're importing your character, please wait a moment...{" "}
                <u>this takes about 30 seconds, so sit tight!</u>
                <br />
                <br />
                <Text color="tomato">
                  <Strong>
                    Reminder: The charcter must be public and have a profile
                    picture.
                  </Strong>
                </Text>
              </Strong>
            </Text>
          </Dialog.Description>
        ) : (
          <Dialog.Description size="2" mb="4">
            Enter the URL below
            <br />
            <br />
            <Text>
              Use the "Copy Link" button on a Character.AI character page to get
              the URL
            </Text>
            <br />
            <br />
            <Text>
              <Strong>
                Please avoid importing underage characters. <br /> <br /> Please
                be patient: this process takes about 30 seconds.
                <br />
                <br />
                <Text color="tomato">
                  <Strong>
                    The charcter must be public and have a profile picture.
                  </Strong>
                </Text>
              </Strong>
            </Text>
          </Dialog.Description>
        )}

        <Flex direction="column" gap="3">
          <TextField.Root
            value={url}
            onChange={(e) => setUrl(e.currentTarget.value)}
            placeholder="Enter a valid Character.AI URL…"
          />
          <Button
            size="3"
            onClick={() => {
              try {
                importCharacter.mutate({
                  caiUrl: url,
                });
              } catch (error) {
                toast.error(
                  `Failed to import character, please make sure the character is public and has a profile picture!`,
                  { duration: 20000, position: "top-center" },
                );
              }
            }}
            loading={importCharacter.isPending}
          >
            Submit
          </Button>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray" onClick={() => {}}>
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button loading={importCharacter.isPending}>Save</Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function AddFromFiggsDialog() {
  const { openCharacter } = useRouterUtils();
  const importCharacter = trpc.generate.createCharacterFromFiggs.useMutation({
    onSuccess: (character) => {
      openCharacter(character.id);
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        `Failed to import character due to an internal error ${error}`,
        { duration: 20000 },
      );
    },
  });
  const [url, setUrl] = useState("");
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Card asChild>
          <Button
            color={"tomato"}
            className={"transition duration-300 ease-in-out transform h-16"}
          >
            <Flex align={"center"} justify={"center"} gap={"1"}>
              <Box width={"32"} height={"32px"}>
                <GiKiwiFruit size={32} />
              </Box>
              <Text>Figgs.ai import</Text>
            </Flex>
          </Button>
        </Card>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Beta: Import a character from Figgs.ai</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Enter the URL below
          <br />
          <br />
          <Text>
            Use the "Copy Link" button on a Figgs.ai character page to get the
            URL
          </Text>
          <br />
          <br />
          <Text>
            <Strong>
              Please avoid importing underage characters. <br /> <br /> Please
              be patient: this process takes about 30 seconds.
            </Strong>
          </Text>
        </Dialog.Description>

        <Flex direction="column" gap="3">
          <TextField.Root
            onChange={(e) => setUrl(e.currentTarget.value)}
            placeholder="Enter a valid Figgs.ai URL…"
          />
          <Button
            onClick={() => {
              importCharacter.mutate({
                figgsUrl: url,
              });
            }}
            loading={importCharacter.isPending}
          >
            Submit
          </Button>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray" onClick={() => {}}>
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button loading={importCharacter.isPending}>Save</Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function AddFromYodayoDialog() {
  const { openCharacter } = useRouterUtils();
  const importCharacter = trpc.generate.createCharacterFromYodayo.useMutation({
    onSuccess: (character) => {
      openCharacter(character.id);
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        `Failed to import character due to an internal error ${error}`,
        { duration: 20000 },
      );
    },
  });
  const [url, setUrl] = useState("");
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Card asChild>
          <Button
            color={"tomato"}
            className={"transition duration-300 ease-in-out transform h-16"}
          >
            <Flex align={"center"} justify={"center"} gap={"1"}>
              <Box width={"32"} height={"32px"}>
                <Gi3DStairs size={32} />
              </Box>
              <Text>Yodayo.com import</Text>
            </Flex>
          </Button>
        </Card>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Beta: Import a character from Yodayo.com</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Enter the URL below
          <br />
          <br />
          <Text>
            Use the "Share Character" button on a Yodayo.com character page to
            get the URL <br />
            <br />
            <Text>
              <Strong>
                The link should look like this:
                https://yodayo.com/tavern/characters/...
              </Strong>
            </Text>
          </Text>
          <br />
          <br />
          <Text>
            <Strong>
              Please avoid importing underage characters. <br /> <br /> Please
              be patient: this process takes about 30 seconds.
            </Strong>
          </Text>
        </Dialog.Description>

        <Flex direction="column" gap="3">
          <TextField.Root
            onChange={(e) => setUrl(e.currentTarget.value)}
            placeholder="Enter a valid Yodayo.com URL…"
          />
          {url.includes("chat") && (
            <Text color="red">
              <b>Error:</b> This is a chat link. <br />
              <br />
              You need to go to Menu - Share Character - Copy Link to get the
              character URL
            </Text>
          )}
          <Button
            onClick={() => {
              importCharacter.mutate({
                yodayoUrl: url,
              });
            }}
            loading={importCharacter.isPending}
          >
            Submit
          </Button>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray" onClick={() => {}}>
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button loading={importCharacter.isPending}>Save</Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
